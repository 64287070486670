import React from 'react';

function NotifyInfo() {
  return (
    <>
        <h1>Notify Us</h1>
        <p>Welcome to the Notify page! Your feedback is invaluable to us. Whether you’ve encountered a bug, have a suggestion for a new feature, or just want to reach out, we’d love to hear from you!</p>

        <h3>How Can We Help You?</h3>

        <ul className="space-between-list-items">
            <li>
                <strong>Report Bugs:</strong> If you notice any glitches or issues while using our application, please let us know. Your input helps us improve the experience for everyone.
            </li>

            <li>
                <strong>Feature Requests:</strong> Do you have an idea that could enhance our application? Share your thoughts, and we’ll consider them for future updates.
            </li>

            <li>
                <strong>General Inquiries:</strong> If you have any questions or just want to say hello, feel free to reach out. We're here to help!
            </li>

            <li>
                <strong>Support Our Work:</strong> If you appreciate our efforts and want to support us, consider making a donation. Your contributions will help us continue to develop and improve our application.
            </li>
        </ul>
    </>
  );
}

export default NotifyInfo;
