// src/components/NotifyForm.jsx
import React from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';

function NotifyForm() {
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        console.log('Form Values:', values); // Log the form values
        try {
            // Convert form values to URLSearchParams
            const formData = new URLSearchParams();
            for (const key in values) {
                formData.append(key, values[key]);
            }

            // Make the POST request with appropriate headers
            const response = await axios.post('/send_email.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            console.log('Response:', response);

            if (response.data.status === 'OK') {
                message.success(response.data.message);
                form.resetFields();
            } else {
                console.error('Response:', response);
                message.error(response.data.message || 'An error occurred.');
            }
        } catch (error) {
            console.error('Error sending form:', error);
            message.error('There was a problem sending your message.');
        }
    };

    return (
        <>
            <h3>Contact Form</h3>
            <p>Please fill out the form below to send us your message:</p>

            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="name"
                    label="Your Name"
                    rules={[{ required: true, message: 'Please enter your name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Your Email"
                    rules={[
                        { required: true, message: 'Please enter your email!' },
                        { type: 'email', message: 'The input is not valid E-mail!' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="message"
                    label="Your Message"
                    rules={[{ required: true, message: 'Please enter your message!' }]}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Send Message
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default NotifyForm;
