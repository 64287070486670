import React from 'react';
import { Button } from 'antd';

function NotifySupport() {
  return (
    <>
        <h3>Support Our Work with Donations</h3>

        <p>
            If you appreciate our work and would like to support us, you can make a donation through PayPal. 
            Your generosity helps us continue improving our application.
        </p>

        <Button type="primary" color="primary" variant="outlined" href="https://paypal.me/acordiccom?country.x=BA&locale.x=en_US" target="_blank">
            Support Us :)
        </Button>

        <h3>Thank You!</h3>
        <p>Thank you for taking the time to reach out and support us. We appreciate your feedback and generosity!</p>
    </>
  );
}

export default NotifySupport;
