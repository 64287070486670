import React from 'react';
import { Col, Row } from 'antd';
import NotifyForm from '../components/notify/NotifyForm';
import NotifyInfo from '../components/notify/NotifyInfo';
import NotifySupport from '../components/notify/NotifySupport';

function Notify() {
  return (
    <div>
        <NotifyInfo />

        <Row
            gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
            }}
            >
            <Col xs={24} sm={24} lg={12} className="gutter-row">
                <NotifyForm />
            </Col>
            <Col xs={24} sm={24} lg={12} className="gutter-row">
                <NotifySupport />
            </Col>
        </Row>
    </div>
  );
}

export default Notify;
